<template>
  <div class="relative bg-white py-11 sm:py-24">
    <div
      class="bg-[url('BackgroundCTA2.png')] bg-no-repeat bg-[length:2000px_550px] sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-48"
    >
      <div class="mx-auto max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
          <div class="mt-16 -mb-16 sm:-mb-48 lg:relative lg:m-0">
            <div
              class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0"
            >
              <img
                class="w-full lg:absolute lg:inset-y-0 lg:left-14 lg:h-[470px] lg:w-auto lg:max-w-none"
                src="../assets/content3.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="mt-20 -mb-16 sm:-mb-48 lg:relative lg:m-0 pr-12 max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left"
          >
            <div class="lg:py-5 lg:mr-11 px-5">
              <h1
                class="text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-12 xl:text-6xl md:mt-10"
              >
                <span class="block">Loan Request</span>
              </h1>
              <p
                class="mt-3 text-base font-light text-black sm:mt-5 sm:text-xl lg:text-lg xl:text-xl lg:w-11/12"
              >
                InstaBusiness understands the importance of convenience and
                simplicity, which is why our loan process has been designed with
                you in mind. With just a few clicks, you can request a loan
                amount ranging from GHS 1000 to GHS 5000, allowing you to access
                the funds you need, when you need them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Content3",
};
</script>
