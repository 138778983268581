<template>
  <div class="relative bg-white sm:py-24">
    <div
      class="text-5xl font-bold tracking-tight mx-auto text-black"
      id="features"
    >
      Features
    </div>
    <div
      class="bg-[url('BackgroundCTA.png')] bg-no-repeat bg-[length:2000px_550px] sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-48"
    >
      <div class="mx-auto max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            class="pr-12 max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left"
          >
            <div class="lg:py-14 lg:mr-11 px-5">
              <h1
                class="text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl md:mt-10"
              >
                <span class="block">Get A Loan</span>
              </h1>
              <p
                class="mt-3 text-base font-light text-black sm:mt-5 sm:text-xl lg:text-lg xl:text-xl lg:w-11/12"
              >
                We offer loans from GHS 1,000 up to GHS 5,000, depending on your
                business's needs and financial profile (credit score).
              </p>
            </div>
          </div>
          <div class="mt-16 -mb-16 sm:-mb-48 lg:relative lg:m-0">
            <div
              class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0"
            >
              <img
                class="w-full lg:absolute lg:inset-y-0 lg:left-10 lg:h-[500px] lg:w-auto lg:max-w-none"
                src="../assets/content2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Content1",

  data() {
    return {
      stats: [
        { label: "Founded", value: "2021" },
        { label: "Employees", value: "5" },
        { label: "Beta Users", value: "521" },
        { label: "Raised", value: "$25M" },
      ],
    };
  },
};
</script>
