<template>
  <div class="mt-3 text-center text-4xl font-bold" id="contact">Contact</div>
  <div class="mt-10 text-center font-bold">
    Please fill the form below to get in touch with us
  </div>
  <div
    class="mx-auto mt-10 max-w-5xl rounded-lg mb-10 bg-white border shadow-lg"
  >
    <div class="p-8 mx-auto">
      <div class="flex gap-4 mb-5">
        <input
          type="Name"
          name="name"
          class="mt-1 block w-1/2 rounded-md bg-white border border-black px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-blue-800 focus:outline-none focus:ring-1 focus:ring-blue-800 sm:text-sm"
          placeholder="Full Name *"
        />
        <input
          type="email"
          name="email"
          class="mt-1 block w-1/2 rounded-md border border-black bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-blue-800 focus:outline-none focus:ring-1 focus:ring-blue-800 sm:text-sm"
          placeholder="Email *"
        />
      </div>
      <div class="flex gap-4 mb-5">
        <input
          type="Name"
          name="phone"
          class="mt-1 block w-1/2 rounded-md border border-black bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-blue-800 focus:outline-none focus:ring-1 focus:ring-border-blue-800 sm:text-sm"
          placeholder="Phone *"
        />
        <input
          type="Name"
          name="company"
          class="mt-1 block w-1/2 rounded-md border border-black bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-blue-800 focus:outline-none focus:ring-1 focus:ring-border-blue-800 sm:text-sm"
          placeholder="Company*"
        />
      </div>
      <div class="">
        <textarea
          name="textarea"
          id="text"
          cols="30"
          rows="10"
          class="mb-10 h-40 w-full resize-none rounded-md border border-black p-5 font-semibold text-gray-300 focus:border-blue-800"
        >
Message</textarea
        >
      </div>
      <div class="text-center">
        <a
          class="cursor-pointer rounded-lg bg-blue-700 px-8 py-5 text-sm font-semibold text-white"
          >Send Message</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactUs",
};
</script>
