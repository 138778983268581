<template>
  <div class="relative bg-white py-16 sm:py-24 lg:py-32">
    <div class="bg-blue-50">
      <div class="p-5 bg-light-blue">
        <div class="flex justify-center items-start my-2">
          <div class="w-full sm:w-10/12 md:w-1/2 my-1">
            <h2 class="text-xl font-semibold text-vnet-blue mb-2">
              Frequently asked questions
            </h2>
            <ul class="flex flex-col">
              <li
                v-for="(item, index) in faqItems"
                :key="index"
                class="bg-white my-2 shadow-lg"
              >
                <h2
                  @click="toggleAccordion(index)"
                  class="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer"
                >
                  <span>{{ item.question }}</span>
                  <svg
                    :class="{ 'rotate-180': activeAccordion === index }"
                    class="fill-current text-blue-700 h-6 w-6 transform transition-transform duration-500"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"
                    />
                  </svg>
                </h2>
                <div
                  :class="{ 'max-h-0': activeAccordion !== index }"
                  class="border-l-2 border-blue-600 overflow-hidden duration-500 transition-all"
                >
                  <p class="p-3 text-gray-900 text-left">{{ item.answer }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqComponent",
  data() {
    return {
      activeAccordion: null,
      faqItems: [
        {
          question: "What is a InstaBusiness?",
          answer:
            "InstaBusiness is a capital focus loan for mobile money agents to assist them in peforming their day to day activities",
        },
        {
          question: "How to sign up?",
          answer:
            "You can sign up either by contacting us or filling the self service form section (create an account). After filling the form a business officer will contact you",
        },
        {
          question: "What is a credit score?",
          answer:
            "credit score determines your eligibility to be given a loan. This credit score is achieved from a 3 month mobile money statement",
        },
        // Add more FAQ items here
      ],
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    },
  },
};
</script>
