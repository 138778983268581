<template>
  <div class="relative overflow-hidden">
    <Popover as="header" class="relative">
      <div class="bg-gradient-to-l from-[#03133D] to-[#1C2792]">
        <nav
          class="relative h-16 mx-auto flex max-w-7xl items-center justify-between px- sm:px-6"
          aria-label="Global"
        >
          <div class="flex flex-1 items-center">
            <div class="flex w-full items-center justify-between md:w-auto">
              <a href="#">
                <!-- <span class="sr-only">Your Company</span> -->
                <img
                  class="h-8 w-auto sm:h-10"
                  src="../assets/iblogo.png"
                  alt=""
                />
              </a>
              <div class="-mr-2 flex items-center justify-between md:hidden">
                <PopoverButton
                  class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-white p-2 text-blue-400 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="hidden space-x-8 md:ml-10 md:flex">
              <a
                v-for="item in navigation"
                :key="item.name"
                @click="scroll(item.ref)"
                class="text-base font-medium text-white hover:underline text-end ml-[500px]"
                >{{ item.name }}</a
              >
            </div>
          </div>
          <div class="hidden md:flex md:items-center md:space-x-6 mr-3">
            <a
              href="#"
              class="inline-flex items-center rounded-md border border-transparent bg-white px-2 py-2 text-base font-medium text-blue-800 hover:bg-white"
              >Create Account</a
            >
          </div>
          <div class="hidden md:flex md:items-center md:space-x-2">
            <a
              href="https://loans.instabusinessgh.com"
              class="inline-flex items-center rounded-md border border-transparent bg-white px-2 py-2 text-base font-medium text-blue-800 hover:bg-white"
              >Request a Loan</a
            >
          </div>
        </nav>
      </div>

      <transition
        enter-active-class="duration-150 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute inset-x-0 top-0 z-10 origin-top transform p-2 transition md:hidden"
        >
          <div
            class="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5"
          >
            <div class="flex items-center justify-between px-5 pt-4">
              <div>
                <img class="h-8 w-auto" src="../assets/iblogo2.png" alt="" />
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                >
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="pt-5 pb-6">
              <div class="space-y-1 px-2">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  @click="scroll(item.ref)"
                  class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >{{ item.name }}</a
                >
              </div>
              <div class="mt-6 px-5">
                <a
                  href="#"
                  class="block w-full rounded-md bg-blue-900 py-3 px-4 text-center font-medium text-white shadow hover:bg-blue-700"
                  >Create Account</a
                >
              </div>
              <div class="mt-6 px-5">
                <a
                  href="https://loans.instabusinessgh.com"
                  class="block w-full rounded-md bg-blue-900 py-3 px-4 text-center font-medium text-white shadow hover:bg-blue-700"
                  >Request a Loan</a
                >
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>

    <main>
      <div
        class="Potential bg-[url('BackgroundHero.png')] bg-no-repeat bg-[length:2000px_815px] sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14 py-12 sm:py-24 h-[890px]"
      >
        <div class="mx-auto max-w-7xl lg:px-8" id="home">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div
              class="pr-12 max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left"
            >
              <div class="lg:py-14 lg:mr-11 px-5">
                <h1
                  class="text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl md:mt-10"
                >
                  <span class="block">Unlock Your Business</span>
                  <span class="block">Potential with Our</span>
                  <span class="block">Flexible Loan Solutions</span>
                </h1>
                <p
                  class="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
                >
                  Easily manage your transactions on the go with the InstaCash
                  Application. Get instant loans with no collateral and no
                  hassle.
                </p>
              </div>
            </div>
            <div class="mt-16 -mb-16 sm:-mb-48 lg:relative lg:m-0">
              <div
                class="mx-auto max-w-md px-2 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0"
              >
                <img
                  class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-[500px] lg:w-auto lg:max-w-none mt-14"
                  src="../assets/content1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Content1 />
      <Content4 />
      <Content3 />
      <Content2 />
      <ContactUs />
      <!-- More main page content here... -->
    </main>
  </div>

  <Footer />
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
//import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import Footer from "./Footer.vue";
import Content1 from "./Content1.vue";
import Content2 from "./Content2.vue";
import Content3 from "./content3.vue";
import Content4 from "./content4.vue";
import ContactUs from "./ContactUs.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Content1,
    Footer,
    Popover,
    PopoverButton,
    PopoverPanel,
    Bars3Icon,
    XMarkIcon,
    //ChevronRightIcon,
    Content2,
    Content3,
    Content4,
    ContactUs,
  },
  data() {
    return {
      navigation: [
        { name: "Home", ref: "home" },
        { name: "Features", ref: "features" },
        { name: "Contact us", ref: "contact" },
      ],
    };
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
